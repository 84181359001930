import styled from "styled-components";
import { BodyDefault } from "../../../../components/typography/styled";

export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 20px;
  width:100%;
  flex-wrap: wrap;
  flex-direction: column;
`
export const TableTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[900]};
  background-color: ${({theme}) => theme.palette[200]};
  padding: 10px;
  margin-bottom: 8px;
  box-sizing: border-box;
  width: 158px;
`

export const AddonBack = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.palette[200]};
  width: 100%;
  gap: 6px;
  border-radius: 4px;
  padding: 6px;
`

export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  /* height:21px; */
  gap:6px;
  flex-direction: column;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  /* align-items: center; */
`

export const TextLabels = styled.p`
  color: ${({theme}) => theme.palette[700]};
  /* Text/Text */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin:0px;
  line-height: 20px;
`

export const AddonImg = styled.img`
  margin-right: 10px;
  width: 20px;
  height: 20px;
`