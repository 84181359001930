export function extractErrorMessage(responseData:any) {
  if (!responseData) return '';

  // Обрабатываем все ключи и значения
  const messages:any = Object.entries(responseData)
    .flatMap(([key, value]) => {
      // Если значение — массив, объединяем его
      if (Array.isArray(value)) {
        return value.map((item) => (typeof item === 'string' ? item : JSON.stringify(item)));
      }

      // Если значение — строка, добавляем её
      if (typeof value === 'string') {
        return value;
      }

      // Если значение — объект, рекурсивно обрабатываем его
      if (typeof value === 'object' && value !== null) {
        return extractErrorMessage(value);
      }

      return [];
    });

  // Возвращаем объединённые сообщения или стандартное сообщение
  return messages.length > 0 ? messages.join(', ') : '';
}