import React, { FC, useEffect, useState } from "react";
import {
  Container,
  AddOnContainer,
  AddonImg
} from './styles'
import { Button, CheckBox, Label, Select, Typography} from "../../../../components";
import { useKubernetes } from "../../../../context/kubernetesContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { StringParam, useQueryParam } from "use-query-params";
import { InfoContainer } from "../../../../components/info-block/styles";
import MetricsIc from './ic-kubernetes.svg'
import CloudNativeIc from './cloudnative.svg'
import PrometheusIc from './Prometheus_software_logo 1.svg'
import RedisIc from './redis-logo-svgrepo-com 1.svg'
import NginxIc from './ic-nginx.svg'
import ArgoIc from './argo.jpg'
import FluxIc from './flux.png'

const icons = [
  {key:'metrics-server', icon:MetricsIc},
  {key:'cloudnative-pg', icon:CloudNativeIc},
  {key:'prometheus', icon:PrometheusIc},
  {key:'redis', icon:RedisIc},
  {key:'argo', icon:ArgoIc},
  {key:'flux', icon:FluxIc},
  {key:'ingress-nginx', icon:NginxIc},
]

const AddOnsPage:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [metrics, setMetrics] = useState<boolean>(getDataToLs({key:'cluster.add-ons'})?.metrics || false)
  const [nginx, setNginx] = useState<boolean>(getDataToLs({key:'cluster.add-ons'})?.nginx || false)
  const [errors, setErrors] = useState<any>()
  const [errorsView, setErrorsView] = useState<boolean>(false)
  const {
    addOns,
    setAddOns,
    addOnsList
  } = useKubernetes()
  useEffect(() => {
    setDataToLs({key:'cluster.add-ons', data:addOns})
  }, [addOns])
  const goNext = () => {
    const noVersions = addOns.filter((add:any) => !add.version)
    console.log('no versions', noVersions);
    if(noVersions.length === 0){
      setStep('finally')
    } else {
      setErrorsView(true)
    }
  }
  // useEffect(() => {
  //   setSelectedClusterType(getDataToLs({key:'cluster.add-ons'})?.id|| 1)
  // }, [])
  
  const addAddons = (addon:any) => {
    const finded = addOns?.find((o:any) => o.name === addon.name)
    if(finded) {
      setAddOns(addOns?.filter((o:any) => o.name !== addon.name))
    } else {
      setAddOns([...addOns, {
        name:addon.name, "version": addon?.releases?.[0]?.value
      }])
    }
  }
  const ChangeAddonVersion = (addon:any, version:any) => {
      setErrorsView(false)
      const finded = addOns?.find((o:any) => o.name === addon.name)
      if(finded) {
        setAddOns(addOns?.filter((o:any) => o.name !== addon.name))
        setAddOns((prev:any) => [...prev, {
          name:addon.name, "version": version
        }])
      }
    }
  // console.log('addonsList', addOnsList);
  console.log('addons', addOns);
  return (
    <Container>
      {/* <Typography variant="h3">
        {t("METRICS")}
      </Typography> */}
      {addOnsList.map((addOn) => (
        <InfoContainer customStyles={{display:'flex', flexDirection:'column', margin:'0px', padding:'12px 20px 6px 20px'}}>
          <div style={{display:'flex', flexDirection:'row'}}>
            <CheckBox
              value={addOns?.find((o:any) => o.name === addOn.name)}
              onChange={() => addAddons(addOn)}
              toolTip={true}
              toolTipText={`${t("KUBERNETES_METRICS_SERVER_INFO")}`}
              title={''}
              withoutText={true}
            />
            {icons.find((ic:any) => ic.key === addOn?.name)?.icon &&
              <AddonImg src={icons.find((ic:any) => ic.key === addOn?.name)?.icon}/>
            }
            <Label margin='10px' toolTip={true} tooltipText={addOn.description} title={addOn.name}/>
          </div>
          <div style={{display:'flex',  flexDirection:'row', gap:'6px', marginLeft:'26px', marginTop:'6px'}}>
            <Typography customStyles={{marginTop:'2px'}} variant='small'>
              {t("VERSION")}
            </Typography>
            <Select
              toolTip={false}
              required={true}
              isSmall
              data={addOn.releases}
              disabled={addOn.releases?.length <= 0 || !addOns?.find((o:any) => o.name === addOn.name)}
              selectedValue={addOns?.find((o:any) => o.name === addOn.name)?.version}
              onChange={(e) => {
                ChangeAddonVersion(addOn,e)}}
              placeholder={addOn.releases?.length <= 0 ? t("NO_DATA") : t("CHOOSE")}
              title={t("VERSION_KUBERNETES")}
              customStyles={{minWidth:'61px'}}
              fieldName='addon_version'
              errors={errorsView && addOns?.find((o:any) => o.name === addOn.name) && !addOns?.find((o:any) => o.name === addOn.name)?.version ? {addon_version:`${t('REQUIRED_FIELD')}`} : {}}
              // {...formikProps}
            />
          </div>
        </InfoContainer>
      ))}
      <div style={{display:'flex', marginTop:'20px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={goNext}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep('configure-worker')}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default AddOnsPage