import React, {FC, useEffect, useState} from "react";
import {CreateContainer} from './styles'
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ClipBoardWrapper, LoaderDashed, ModalVariables, StartPage, Typography } from "../../components";
import TutorImg from './illustration.svg'
import TutorDarkImg from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { authService, kubernetesService, operationService } from "../../api";
import { timeConverterMMDDYYHHMM } from "../../utils/dates";
import { GreenDot, Greentext, StateWrapper } from "../Instances/styles";
import { useUi } from "../../context/ui.context";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../context/user.context";
import { handleStatusName, handleStatusTask } from "../../utils/handle-status-name";
import CreateImage from './ill.svg'
import AutoIcon from './ic-auto.svg'
import GuardIcon from './ic-guarantee.svg'
import DocIcon from './document.svg'
import { compareArrays } from "../../utils/findIntersection";
import rootConfig from "../../config";
import { extractErrorMessage } from "../../utils/error-extractor";

const Kubernetes:FC = () => {
  const [clusters, setClusters] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam);
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedImage, setSelectedImage] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [selectedOperation, setSelectedOperation] = useState<any>()
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()

  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setAllChecked(false)
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {setDataToLs} = useLocalStorage()
  const _updateClusters = () => {
    // setLoading(true)
    kubernetesService.getList({
      page:page || 1,
      pageSize:pageSize || 10,
      ordering:ordering,
    })
    .then((res) => {
      setClusters([
        ...res.data.operations.map((operation:any) => {
          return{
            ...operation,
            dropOptions:[
              {
                title:'Abort',
                onClick:(operation:any) => {
                  setSelectedOperation(operation)
                  setAlertTitle(t("ABORT_OPERATION"))
                  setAlertSubTitle(`${t("ABORT_OPERATION_ALERT")} ${operation.id}`)
                  openModal('universal')
                },
                id:'3',
                icon:icons.CloseIconBold,
                divider:false
              },
            ],
            navigate:`/operations/${operation.id}`,
            created_at:timeConverterMMDDYYHHMM(operation.created_at),
            isOperation:true,
            display_task: operation?.status_reason ? handleStatusTask(operation?.status_reason, t) : null,
            name:(
              <div style={{display:'flex', alignItems:'center'}}>
                {operation?.name}
                {operation?.status_reason ?
                  <LoaderDashed title={handleStatusTask(operation?.status_reason, t)}/>
                :
                  <LoaderDashed title={t("IN_LINE")}/>
                }
              </div>
            ),
            status: 
            (<StateWrapper>
              <GreenDot style={{backgroundColor:'#E8B533'}}/>
              <Greentext style={{color:'#E8B533'}}>
                {t("IN_PROGRESS")}
              </Greentext>
            </StateWrapper>),
          }
        }),
        ...res.data.objects.map((cluster:any) => {
        return {
          ...cluster,
          navigate: `${cluster.id}`,
          api_address:(
            <ClipBoardWrapper>
              {cluster?.api_address}
            </ClipBoardWrapper>
          ),
          name: cluster.cluster_name || cluster.id,
          created_at:timeConverterMMDDYYHHMM(cluster.created_at),
          status:(
          <StateWrapper>
            <GreenDot active={cluster.status}/>
            <Greentext style={{textTransform:"capitalize"}} active={cluster.status}>
              {handleStatusName(cluster.status,t)}
            </Greentext>
          </StateWrapper>) ,
        }
      })])
      setPaginationData(res.data)
      setPageLoading(false)
    })
    .catch((e) => {
      // viewAlert({
      //   severity:'error',
      //   message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      // })
      setPageLoading(false)
    })
  }
  useEffect(() => {
    _updateClusters()
    const interval = setInterval(() => {
      _updateClusters()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  
  const _deleteVolume = async () => {
    setLoading(true);
    try {
      if (allChecked) {
        await Promise.all(clusters.map((item:any) => kubernetesService.deleteCluster(item.id)));
        _updateClusters();
        setAllChecked(false);
        setChecked([]);
      } else if (checked?.length > 0) {
        await Promise.all(checked.map((item) => kubernetesService.deleteCluster(item.id)));
        _updateClusters();
        setChecked([]);
      } else if (selectedImage) {
        await kubernetesService.deleteCluster(selectedImage.id);
        _updateClusters();
      }
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setChecked([]);
    } finally {
      setViewModal(false);
      setActiveAnimation(false);
      setLoading(false);
    }
  };
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = async (values:any) => {
    let res:any
    try {
      setLoading(true);
      switch (modalType) {
        case 'delete':
          _deleteVolume()
          break;
        case 'upgrade-cluster':
          res = await kubernetesService.upgradeCluster(selectedImage.id, values)
          break;
        case 'universal':
        if (alertTitle === `${t('ABORT_OPERATION')}`) {
          res = await operationService.abortOperation(selectedOperation.id)
        } 
        break;
        case 'cluster-alert':
          // setViewModal(false)
          // setActiveAnimation(false)
          _createFunc()
      }
      if(modalType !== 'delete'){
        setLoading(false)
        await setLoadingComplete(true)
        await setTimeout(() => {
          setViewModal(false)
          setLoadingComplete(false)
          setActiveAnimation(false)
        },1500)

      }

    } catch (e: any) {
      console.log('e',e);
      const error = extractErrorMessage(e?.response?.data)
      if (error) {
        viewAlert({
          severity: 'error',
          message: error,
        });
      } else {
        viewAlert({
          severity: 'error',
          message: `${t('ERROR_OCCURRED')}`,
        });
      }
      if(modalType !== 'delete'){
        setLoading(false);
      }
    } finally {

    }
    // setViewModal(false)
    // setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const items = [
    // {
    //   title:t("LAUNCH"),
    //   id:'2',
    //   icon:icons.Launch,
    //   onClick: (image:any) => {
    //     setDataToLs({key:'instance.configure', data:{os:image?.id}})
    //     navigate('/instances/create?step=configure')
    //   },
    //   divider:false
    // },
    // {
    //   title:t("EDIT"),
    //   id:'3',
    //   icon:icons.Edit,
    //   onClick: (image:any) => {
    //     setSelectedImage(image)
    //     navigate(`${image.id}/edit`)
    //   },
    //   divider:false
    // },
    {
      title:t("DOWNLOAD_KUBE_CONFIG"),
      id:'7',
      icon:icons.Download,
      onClick:async (cluster:any) => {
        const res = await authService.generateOtpToken()
        const url = `${rootConfig.apiUrl}api/cluster/${cluster.id}/download_config/?fleio-token=${res.data.otp_token}`
        // window.open(url);
        window.location.href = url
      }
    },
    {
      title:t("UPGRADE_K8S"),
      id:'6',
      icon:icons.CopyProps,
      onClick: (image:any) => {
        setAlertTitle(t("UPGRADE_K8S"))
        setAlertSubTitle([image])
        openModal('upgrade-cluster')
        setSelectedImage(image)
      },
      divider:false,
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(image:any) => {
        openModal('delete')
        setAlertTitle(t("CLUSTER"))
        setAlertSubTitle([image])
        setSelectedImage(image)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    // {
    //   title:t("PROTECTED"),
    //   key:'protected'
    // },
    {
      title:t("KUBE_API"),
      key:'api_address'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:t("VERSION_KUBERNETES_TITLE"),
      key:'cluster_version'
    },
    {
      title:'',
      key:'props'
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else {
      navigate('create')
    }
  }

  const data = [
    {
      id:'1',
      content:t("TUTOR_AUTO_CLUSTER"),
      iconFirst:AutoIcon
    },
    {
      id:'2',
      content:t("TUTOR_GUARANTEE_CLUSTER"),
      iconFirst:GuardIcon
    },

  ]

  const guideItems = [
    {
      title:t("DOCUMENTATION_CUBERNETES"),
      id:'1',
      content:t("DOCUMENTATION_CUBERNETES_DESCRIPTION"),
      icon:DocIcon,
      isTutorial:true,
    }
  ]
  useEffect(() => {
    if(compareArrays(clusters, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  return (
    <>
      <StartPage
        title={`${t("CLUSTERS")}`}
        // noResourceText={`${t("THERE_ARE_NO_IMAGES_YET")}`}
        createResourceButton={`${t('CREATE_KUBERNETES_CLUSTER')}`}
        createResourceButtonEventOrLink={() => openModal('cluster-alert')}
        haveResource={clusters.length > 0}
        howCreateVideoText={`${t('HOW_CREATE_CLUSTER')}`}
        withoutCreateResourceButton={clusters.length === 0}
        tutorialLeftData={data}
        tutorialRightData={guideItems}
        howCreateVideoUrl="https://www.youtube.com/embed/yo4RDneQ8Ac"
        rightCustomCreateButton={
          <CreateContainer
            style={{backgroundImage:`url(${CreateImage})`}}
          >
            <div style={{maxWidth:"460px"}}>
              <Typography variant="h2">
                {t("CREATE_CLUSTER_TEXT")}
              </Typography>
            </div>
            <Button
              title={`${t('CREATE_KUBERNETES_CLUSTER')}`}
              // onClick={_createFunc}
              onClick={() => openModal('cluster-alert')}
              variant='primary'
              size='custom'
              customStyles={{height:'40px'}}
            />
          </CreateContainer>
        }
        rightButtonComponentCustomWithResources={
          <div
          >
            <Button
              onClick={() => {
                setAlertTitle(t("CLUSTERS"))
                setAlertSubTitle(allChecked ? clusters : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImage={TutorImg}
        videoImageDark={TutorDarkImg}
        pageLoading={pageLoading}
        tableData={{
          columns:Columns,
          data:clusters,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          paginationData:paginationData,
          updateData:_updateClusters
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={selectedImage}
        loadingComplete={loadingComplete}
      />
    </>
  )
}


export default Kubernetes

